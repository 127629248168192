import React, {useEffect, useState} from 'react'
import {
    AppBar,
    Box, Button, Stack,
    Toolbar,
} from '@mui/material'
import MenuLanguage from "../../menu/menu-language.componement";
import MenuTopAnonymousCandidateMobile
    from "../../menu/menu-top-anonymous-candidate-mobile/menu-top-anonymous-candidate-mobile.component";
import {useRouter} from "next/router";
import {useTranslation} from "next-i18next";
const buttonLEftCss={
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "17px",
    letterSpacing: "0.05em",
}
const NavbarTopAnonymousCandidateMobile = ()=>{
    const router = useRouter()
    const {t} = useTranslation(['menu']);
    const [scrollY,setScrollY] =  useState(0)
    useEffect(()=>{
        const onScroll = e => {
            setScrollY(window.scrollY)
        };
        window.addEventListener("scroll", onScroll);
    },[scrollY])
    return (
        <AppBar position="sticky" elevation={scrollY!==0?3:0} color={'inherit'} sx={{ display: { xs: 'block' , sm: 'block', md:'block',lg:'none'  } }}>
            <Toolbar variant={'dense'} sx={{
                display: 'flex',
                justifyContent:'space-between'
            }}>
                    <Button  onClick={()=>router.push('/')} color={'primary'}  size={'small'} variant="contained" sx={{
                            fontFamily: "Lato",
                            fontStyle: "normal",
                            fontWeight: "normal",
                            fontSize: { xs: '12px',sm:'12px', md:'16px' },
                            lineHeight: "19px",
                            letterSpacing: "0.07em"
                    }}>{t('_TKEY_MENU_1', { ns: 'menu' })}</Button>
                    <Box>
                        <Stack direction="row" spacing={2}>
                        <MenuLanguage size={'small'} id={'menu-language-mobile-candidate-2'}  />
                        </Stack>
                    </Box>
        </Toolbar>
            <Box sx={{ml:2,mr:2,display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                <Box >
                    <MenuTopAnonymousCandidateMobile />
                </Box>
            </Box>

        </AppBar>
    )

}

export  default  NavbarTopAnonymousCandidateMobile